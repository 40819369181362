import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
// import Image from "../components/image"
import SEO from "../components/seo"
import IndexCard from "../components/index-card"
import {
  Container,
  Row,
  Col,
  Card,
  ListGroup
} from 'react-bootstrap';
import resume from "./Resume-Logan-Besecker.pdf"


const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <Container>
      <Row>
        <Col>
          <h1 style={{textAlign: `center`, margin: '20px 0'}}>Logan Besecker</h1>
        </Col>
      </Row>
      <Row>
        <Col>
          <IndexCard title="Qualifications" button={{link: resume, text: "View Resume"}} items={["Assoc. Degree in Computer Science*", "Assoc. Degree in Mathematics*", "Computer Science Certificates*", "Dean's List", "Eagle Scout"]}/>
        </Col>
        <Col>
          <IndexCard title="Experience" button={{link: '/hire-me', text: "Hire Me"}} items={["Stingray Technologies -- Founder, Software Engineer", "Google -- Software Engineer Contractor (2 year)", "Freelancer -- Web Developer", "Touchpoint Promotions -- Junior Software Developer"]}/>
        </Col>
        <Col>
          <IndexCard title="Projects" button={{link: '/projects', text: "Project Details"}} items={["Make Data Searchable", "GroupChant", "WoW Classic Raids", "API2Site", "Give and Get Leads"]}/>
        </Col>
      </Row>
    </Container>
  </Layout>
)

export default IndexPage
